/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import banner from "../../static/imgs/kanha-celebration-bgimg.jpg";
import { eventCallbackTriggers } from "../templates/wppage";

const DonationsDriveUsaLarge = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Heartfulness Donation Drive, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("heartfulness_donation_drive,_USA", event),
    []
  );

  const renderContentHeader = () => (
    <Box
      sx={{
        marginBottom: "2rem",
        paddingTop: "30px",
        alignItems: "center",
        minHeight: "38px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${banner})`,
      }}
    >
      <Box sx={{ paddingBottom: "30px" }}>
        <h3
          sx={{
            color: "White",
            marginTop: "30px",
            fontWeight: "400",

            textAlign: "center!important",
            fontSize: "1.7rem",
          }}
        >
          Heartfulness Donations
        </h3>
        <h2
          sx={{
            color: "#e2aa00",
            fontWeight: "450",
            lineHeight: "1.2",
            fontSize: "45px",
            marginBottom: "1rem",
            textAlign: "center!important",
          }}
        >
          General Donation Fund USA
        </h2>
        <div
          className="test"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <StaticImage
            src="../../static/imgs/years-celebrations-icon.png"
            alt="Logo"
            sx={{
              maxHeight: "170px",
              maxWidth: "171px",
            }}
          />
        </div>
      </Box>
    </Box>
  );

  return (
    <LayoutHome
      contentHeader={renderContentHeader()}
      seoTitle="General Donation Fund USA - Large - Donations"
    >
      <Grid>
        <Box>
          <p style={{ textAlign: "justify" }}>
            A Donation Drive for development of retreat centers & initiatives
            for expansion of Heartfulness Movement in 2020 is being put forth to
            seek your support toward General Donation Fund.
          </p>
          <p style={{ textAlign: "justify" }}>
            Please make one or more selections from the options below. You may
            also choose all the options by selecting `All of the above`. On
            submission of the donation, a confirmation email will be sent to you
            with the Donation receipt.{" "}
            <b>There are no refunds for the donations.</b> Your support is
            greatly appreciated.
          </p>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(17, "Donate Now")}
          />
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default DonationsDriveUsaLarge;
